import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        padding: 0.8rem 0 0.8rem 1.8rem;
        display: inline-flex;
        border-radius: ${theme.radius.small};

        label {
            .MuiCheckbox-root {
                margin: -0.9rem;
                margin-right: 0;
            }
        }
    `}
`;
