import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SuccessAlert } from './success-alert';
import { ErrorAlert } from './error-alert';
import { WarningAlert } from './warning-alert';
import { Wrapper, Inner } from './alerts.styled';
import { useRootStore } from '../../../providers/root-store-provider';
import { ErrorAlertEmailNotConfirmed } from './error-alert-email-not-confirmed';
import { AlertPlacementEnum } from '../../../models/enums/alert-placement-enum';

interface AlertsPropsInterface {
    email?: string;
}

export const Alerts: React.FC<AlertsPropsInterface> = observer(({ email }: AlertsPropsInterface) => {
    const { t } = useTranslation();
    const { alertStore } = useRootStore();

    if (alertStore.alertPlacement !== AlertPlacementEnum.Default) {
        return null;
    }

    return (
        <Wrapper>
            <Inner>
                {alertStore.successMessage ? (
                    <SuccessAlert
                        message={alertStore.successMessage}
                        onCloseClick={() => {
                            alertStore.setSuccessMessage('');
                        }}
                    />
                ) : null}

                {alertStore.errorMessage ? (
                    <ErrorAlert
                        message={alertStore.errorMessage}
                        loading={alertStore.loading}
                        onCloseClick={() => {
                            alertStore.setErrorMessage('');
                        }}
                        afterMessage={
                            alertStore.errorMessage === t('errors.emailNotConfirmed') && email ? (
                                <ErrorAlertEmailNotConfirmed email={email} />
                            ) : null
                        }
                    />
                ) : null}

                {alertStore.warningMessage ? (
                    <WarningAlert message={alertStore.warningMessage} loading={alertStore.loading} />
                ) : null}
            </Inner>
        </Wrapper>
    );
});
