import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Checkbox } from '../../../../../app/form/checkbox/checkbox';
import { routes } from '../../../../../../config/routes';
import { useRootStore } from '../../../../../../providers/root-store-provider';
import { Wrapper } from './agree-terms.styled';

interface AgreeTermsProps {
    handleChange?: (e: React.ChangeEvent<never>) => void;
}

export const AgreeTerms: React.FC<AgreeTermsProps> = observer(({ handleChange }) => {
    const {
        authStore: { authDialogStore },
    } = useRootStore();

    const onChange = () => {
        authDialogStore.switchAgreedTerms();

        if (handleChange) {
            handleChange({
                target: {
                    name: 'agreedTerms',
                    value: authDialogStore.agreedTerms,
                },
            } as React.ChangeEvent<never>);
        }
    };

    return (
        <Wrapper isChecked={authDialogStore.agreedTerms}>
            <Checkbox
                name="agreedTerms"
                checked={authDialogStore.agreedTerms}
                onChange={onChange}
                label={
                    <Trans
                        i18nKey={'layout.forms.common.agreeTerms'}
                        components={{
                            terms: (
                                <a href={routes.web.termsAndConditions} target={'_blank'} rel="noreferrer">
                                    Link
                                </a>
                            ),
                            privacy: (
                                <a href={routes.web.privacyPolicy} target={'_blank'} rel="noreferrer">
                                    Link
                                </a>
                            ),
                        }}
                    />
                }
            />
        </Wrapper>
    );
});
