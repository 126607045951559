import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import { Checkbox } from '../../../../../app/form/checkbox/checkbox';
import { routes } from '../../../../../../config/routes';
import { useRootStore } from '../../../../../../providers/root-store-provider';
import { Wrapper } from './agree-marketing.styled';

interface AgreeMarketingProps {
    handleChange?: (e: React.ChangeEvent<never>) => void;
}

export const AgreeMarketing: React.FC<AgreeMarketingProps> = observer(({ handleChange }) => {
    const {
        authStore: { authDialogStore },
    } = useRootStore();

    const onChange = () => {
        authDialogStore.switchAgreedMarketing();

        if (handleChange) {
            handleChange({
                target: {
                    name: 'agreedMarketing',
                    value: authDialogStore.agreedMarketing,
                },
            } as React.ChangeEvent<never>);
        }
    };

    return (
        <Wrapper>
            <Checkbox
                name="agreedMarketing"
                checked={authDialogStore.agreedMarketing}
                onChange={onChange}
                label={
                    <Trans
                        i18nKey={'layout.forms.common.agreeMarketing'}
                        components={{
                            marketing: (
                                <a href={routes.web.marketingCommunicationTerms} target={'_blank'} rel="noreferrer">
                                    Link
                                </a>
                            ),
                        }}
                    />
                }
            />
        </Wrapper>
    );
});
